import {
  CASHFLOW_PARAM_CREATE_TYPE,
  GET_CASHFLOW_RESULT_TYPE,
  INDIVIDUAL_CASHFLOW_PARAM_TYPE,
  UPDATE_CASHFLOW_TYPE,
} from "../../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../../utils/createAppAsyncThunk";
import {
  CashflowResultService,
  CreatCashFlowService,
  GetIndividualCashflowParamService,
  UpdateCashFlowctService,
} from "./cashFlowServices";

// cash flow
// export const getProjects = createAppAsyncThunk(
//   GET_PROJECTS_TYPE,
//   GetProjectService,
//   {}
// );

export const getIndividualCashflowParam = createAppAsyncThunk(
  INDIVIDUAL_CASHFLOW_PARAM_TYPE,
  GetIndividualCashflowParamService
);

export const creatCashFlow = createAppAsyncThunk(
  CASHFLOW_PARAM_CREATE_TYPE,
  CreatCashFlowService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getCashFlowResult = createAppAsyncThunk(
  GET_CASHFLOW_RESULT_TYPE,
  CashflowResultService
);

export const updateCashFlow = createAppAsyncThunk(
  UPDATE_CASHFLOW_TYPE,
  UpdateCashFlowctService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
