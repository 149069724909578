import {
  ASSET_URL,
  FORGOT_PASSWORD_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REFRESH_TOKEN_URL,
  SEND_PASSWORD_LINK_URL,
  SET_PASSWORD_URL,
  USER_PROFILE_URL,
  VALIDATE_AZURE_LINK_URL,
  VALIDATE_LINK_URL,
} from "../../../config/config";
import axiosConfig from "../../../config/axiosConfig";
import axios from "axios";

export const LoginService = async (data) => {
  return await axiosConfig.post(LOGIN_URL, data);
};

export const ValidateLinkService = async (params) => {
  const { token, id, timestamp } = params;
  return axiosConfig.get(
    `${VALIDATE_LINK_URL}?token=${token}&id=${id}&timestamp=${timestamp}`
  );
};

export const ValidateAzureLinkService = async (data) => {
  return axiosConfig.post(VALIDATE_AZURE_LINK_URL, data);
};

export const SetPasswordService = async (data) => {
  return await axiosConfig.post(SET_PASSWORD_URL, data);
};

export const ForgotPasswordService = async (data) => {
  return await axiosConfig.post(FORGOT_PASSWORD_URL, data);
};

export const ResendPasswordLinkService = async (data) => {
  return await axiosConfig.post(SEND_PASSWORD_LINK_URL, data);
};

export const RefreshTokenSerivce = async (data) => {
  return await axios.post(REFRESH_TOKEN_URL, data);
};

export const LogoutService = async (data) => {
  return await axiosConfig.post(LOGOUT_URL, data);
};

export const GetUsetrProfileService = async (params) => {
  const { id } = params;
  return await axiosConfig.get(`${USER_PROFILE_URL}?id=${id}`);
};

export const GetAssetStageService = (params) => {
  const { slug } = params;
  try {
    return axiosConfig.get(`${ASSET_URL}/stage/${slug}`);
  } catch (error) {
    throw error;
  }
};

export const UpdateStageService = (params) => {
  const { slug, stage } = params;
  try {
    return axiosConfig.patch(`${ASSET_URL}/stage/${slug}`, { stage });
  } catch (error) {
    throw error;
  }
};
