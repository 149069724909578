import axiosConfig from "../../../config/axiosConfig";
import { ANALYTICS_URL } from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetPinnedGraphsService = (params) => {
  try {
    const { user } = params || {};

    const paramsList = {
      user,
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ANALYTICS_URL}/home/pinned/graphs${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const PinGraphService = (data) => {
  const url = `${ANALYTICS_URL}/home/pinned/graphs`;
  return axiosConfig.post(url, data);
};
