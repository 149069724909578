import {
  ANALYTICS_FILTER_TYPE,
  ANALYTICS_GRAPHS_TYPE,
  GET_GRAPHS_LIST_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetAnalyticsFiltersService,
  GetAnalyticsGraphsService,
  GetGraphsListService,
} from "./analyticsServices";

export const getGraphsList = createAppAsyncThunk(
  GET_GRAPHS_LIST_TYPE,
  GetGraphsListService
);

export const analyticsFilterDropdowns = createAppAsyncThunk(
  ANALYTICS_FILTER_TYPE,
  GetAnalyticsFiltersService
);

export const analyticsGraphsJSON = createAppAsyncThunk(
  ANALYTICS_GRAPHS_TYPE,
  GetAnalyticsGraphsService
);
