import {
  ENGINE_INDIVIDUAL_CASHFLOW_PARAM_TYPE,
  ENGINE_UPDATE_CASHFLOW_TYPE,
  GET_ENGINE_CASHFLOW_RESULT_TYPE,
} from "../../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../../utils/createAppAsyncThunk";
import {
  EngineCashflowResultService,
  GetEngineIndividualCashflowParamService,
  UpdateEngineCashFlowctService,
} from "./engineCashFlowService";

// import {
//   CashflowResultService,
//   CreatCashFlowService,
//   GetEngineIndividualCashflowParamService,
//   GetIndividualCashflowParamService,
//   UpdateCashFlowctService,
// } from "./cashFlowServices";

// cash flow
// export const getProjects = createAppAsyncThunk(
//   GET_PROJECTS_TYPE,
//   GetProjectService,
//   {}
// );

export const getEngineIndividualCashflowParam = createAppAsyncThunk(
  ENGINE_INDIVIDUAL_CASHFLOW_PARAM_TYPE,
  GetEngineIndividualCashflowParamService
);

// export const creatCashFlow = createAppAsyncThunk(
//   CASHFLOW_PARAM_CREATE_TYPE,
//   CreatCashFlowService,
//   {
//     successMessage: { show: true },
//     errorMessage: { show: true },
//   }
// );

export const getEngineCashFlowResult = createAppAsyncThunk(
  GET_ENGINE_CASHFLOW_RESULT_TYPE,
  EngineCashflowResultService
);

export const updateEngineCashFlow = createAppAsyncThunk(
  ENGINE_UPDATE_CASHFLOW_TYPE,
  UpdateEngineCashFlowctService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
