import { createSlice } from "@reduxjs/toolkit";
import { getPiecePart } from "./purchaseAction";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  purchase: {},
};

const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    clearPurchaseListData: (state) => {
      state.initialState = false;
      state.loading = false;
      state.purchase = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // project list
      .addCase(getPiecePart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPiecePart.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.purchase = action.payload;
      })
      .addCase(getPiecePart.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.purchase = {};
      });
  },
});

export const {
  setInitialLoad,
  isInitialLoad,
  setRoleInitalLoad,
  clearPurchaseListData,
} = purchaseSlice.actions;
export default purchaseSlice.reducer;
