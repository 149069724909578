import axiosConfig from "../../../../config/axiosConfig";
import { CASHFLOW_RESULT_URL, CASHFLOW_URL } from "../../../../config/config";
import { createQueryParams } from "../../../../utils/helper";

export const GetIndividualCashflowParamService = (params = {}) => {
  try {
    const { project_assets_slug } = params;
    const paramsList = {
      project_assets_slug,
    };
    const queryString = createQueryParams(paramsList);
    const url = `${CASHFLOW_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const CreatCashFlowService = ({ data }) => {
  return axiosConfig.post(`${CASHFLOW_URL}`, data);
};

export const CashflowResultService = (params = {}) => {
  try {
    const { project_assets_slug } = params;
    const paramsList = {
      project_assets_slug,
    };
    const queryString = createQueryParams(paramsList);

    const url = `${CASHFLOW_RESULT_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const UpdateCashFlowctService = ({ id, data }) => {
  return axiosConfig.put(`${CASHFLOW_URL}/${id}`, data);
};
