import {
  ENGINE_CASHFLOW_RESULT_URL,
  ENGINE_CASHFLOW_URL,
} from "../../../../config/config";
import axiosConfig from "../../../../config/axiosConfig";
import { createQueryParams } from "../../../../utils/helper";

export const GetEngineIndividualCashflowParamService = (params = {}) => {
  try {
    const { project_assets_slug } = params;
    const paramsList = {
      project_assets_slug,
    };
    const queryString = createQueryParams(paramsList);
    const url = `${ENGINE_CASHFLOW_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

// export const CreatCashFlowService = ({ data }) => {
//   return axiosConfig.post(`${CASHFLOW_URL}`, data);
// };

export const EngineCashflowResultService = (params = {}) => {
  try {
    const { project_assets_slug, is_export } = params;
    const paramsList = {
      project_assets_slug,
      is_export,
    };
    const queryString = createQueryParams(paramsList);

    const url = `${ENGINE_CASHFLOW_RESULT_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const UpdateEngineCashFlowctService = ({ id, data }) => {
  return axiosConfig.put(`${ENGINE_CASHFLOW_URL}/${id}`, data);
};
