import {
  GET_VENDOR_COMPANY_CLASS_GRAPH_TYPE,
  GET_VENDOR_DATE_CREATED_GRAPH_TYPE,
  GET_VENDOR_OFFICE_REGION_GRAPH_TYPE,
  GET_VENDOR__CUSTOMER_QUOTES_DETAILS_TYPE,
  GET_VENDOR__PO_DETAILS_TYPE,
  GET_VENDOR__QUOTES_DETAILS_TYPE,
  GET_VENDOR__REPAIR_DETAILS_TYPE,
  GET_VENDOR__SERVICES_DETAILS_TYPE,
  GET_VENDOR__SO_DETAILS_TYPE,
  GET_VENDORS_TYPE,
  TOTAL_VENDOR_OVERVIEW,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetVendorCompanyClassGraph,
  GetVendorDateCreatedGraph,
  GetVendorOfficeRegionGraph,
  GetVendorCustomerQuoteDetailsService,
  GetVendorInvoiceDetailsService,
  GetVendorPODetailsService,
  GetVendorQuoteDetailsService,
  GetVendorRepairDetailsService,
  GetVendorSODetailsService,
  GetVendorsService,
  getTotalVendorOverviewService,
} from "./vendorsManagementServices";

export const getVendors = createAppAsyncThunk(
  GET_VENDORS_TYPE,
  GetVendorsService,
  {}
);

export const getVendorCompanyClassGraph = createAppAsyncThunk(
  GET_VENDOR_COMPANY_CLASS_GRAPH_TYPE,
  GetVendorCompanyClassGraph
);

export const getTotalVendorOverview = createAppAsyncThunk(
  TOTAL_VENDOR_OVERVIEW,
  getTotalVendorOverviewService
);

export const getVendorOfficeRegionGraph = createAppAsyncThunk(
  GET_VENDOR_OFFICE_REGION_GRAPH_TYPE,
  GetVendorOfficeRegionGraph
);

export const getVendorDateCreatedGraph = createAppAsyncThunk(
  GET_VENDOR_DATE_CREATED_GRAPH_TYPE,
  GetVendorDateCreatedGraph
);
export const getVendorPODetails = createAppAsyncThunk(
  GET_VENDOR__PO_DETAILS_TYPE,
  GetVendorPODetailsService,
  {}
);

export const getVendorSODetails = createAppAsyncThunk(
  GET_VENDOR__SO_DETAILS_TYPE,
  GetVendorSODetailsService,
  {}
);

export const getVendorCustomerQuotesDetails = createAppAsyncThunk(
  GET_VENDOR__CUSTOMER_QUOTES_DETAILS_TYPE,
  GetVendorCustomerQuoteDetailsService,
  {}
);

export const getVendorQuotesDetails = createAppAsyncThunk(
  GET_VENDOR__QUOTES_DETAILS_TYPE,
  GetVendorQuoteDetailsService,
  {}
);

export const getVendorInvoicesDetails = createAppAsyncThunk(
  GET_VENDOR__SERVICES_DETAILS_TYPE,
  GetVendorInvoiceDetailsService,
  {}
);

export const getVendorRepairDetails = createAppAsyncThunk(
  GET_VENDOR__REPAIR_DETAILS_TYPE,
  GetVendorRepairDetailsService,
  {}
);
