import { createSlice } from "@reduxjs/toolkit";
import { getPinnedGraphs, pinGraph } from "./homeActions";

export const initialState = {
  loading: false,
  pinnedGraphs: [],
  pinLoader: false,
};

const home = createSlice({
  name: "home",
  initialState,
  reducers: {
    clearHomeState: (state) => {
      state.loading = false;
      state.pinnedGraphs = [];
      state.pinLoader = false;
    },
    setPinnedGraphs: (state, action) => {
      state.pinnedGraphs = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPinnedGraphs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPinnedGraphs.fulfilled, (state, action) => {
        state.loading = false;
        state.pinnedGraphs = action.payload?.data;
      })
      .addCase(getPinnedGraphs.rejected, (state, action) => {
        state.loading = false;
      })
      .addCase(pinGraph.pending, (state) => {
        state.pinLoader = true;
      })
      .addCase(pinGraph.fulfilled, (state, action) => {
        state.pinLoader = false;
      })
      .addCase(pinGraph.rejected, (state, action) => {
        state.pinLoader = false;
      });
  },
});
export const { clearHomeState, setDefaultPath, setPinnedGraphs } = home.actions;
export default home.reducer;
