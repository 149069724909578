import { createSlice } from "@reduxjs/toolkit";
import {
  getPricing,
  getPricingSummary,
  updatePricing,
  updatePricingRP,
} from "./prcingActions";

export const initialState = {
  isInitialLoad: true,
  summaryIntialLoad: true,
  loading: false,
  pricingData: {},
  summaryData: {},
  updateLoader: false,
};

const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setSummaryInitialLoad: (state, action) => {
      state.summaryIntialLoad = action.payload;
    },
    clearPricingData: (state) => {
      state.initialState = true;
      state.loading = false;
      state.pricingData = {};
      state.summaryData = {};
      state.updateLoader = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // pricing list
      .addCase(getPricing.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPricing.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.pricingData = action.payload?.data;
      })
      .addCase(getPricing.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // summary list
      .addCase(getPricingSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPricingSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryIntialLoad = false;
        state.summaryData = action.payload?.data;
      })
      .addCase(getPricingSummary.rejected, (state) => {
        state.loading = false;
        state.summaryIntialLoad = false;
      })
      // update pricing list
      .addCase(updatePricing.pending, (state) => {
        state.updateLoader = true;
        state.isInitialLoad = true;
      })
      .addCase(updatePricing.fulfilled, (state, action) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
        state.pricingData = action.payload?.data;
      })
      .addCase(updatePricing.rejected, (state) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
      })
      // update bulk rp
      .addCase(updatePricingRP.pending, (state) => {
        state.updateLoader = true;
        state.isInitialLoad = true;
      })
      .addCase(updatePricingRP.fulfilled, (state, action) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
      })
      .addCase(updatePricingRP.rejected, (state) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
      });
  },
});

export const { setInitialLoad, clearPricingData, setSummaryInitialLoad } =
  pricingSlice.actions;
export default pricingSlice.reducer;
