import { createSlice } from "@reduxjs/toolkit";
import {
  forgotPassword,
  getAssetStage,
  getUserProfile,
  resendPasswordLink,
  setPassword,
  updateAssetStage,
  userLogin,
  userLogout,
  validateAzureLink,
  validateLink,
} from "./authActions";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  isValidLink: true,
  user: {},
  defaultPath: "",
  currentAssetStage: 0,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    clearAuthState: (state) => {
      state.isInitialLoad = true;
      state.loading = false;
      state.isValidLink = true;
      state.user = {};
    },
    setDefaultPath: (state, action) => {
      state.defaultPath = action.payload;
    },
    setAssetStage: (state, action) => {
      state.currentAssetStage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // login
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload?.data;
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
      })
      // validate password link
      .addCase(validateLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateLink.fulfilled, (state, action) => {
        state.loading = false;
        state.isValidLink = true;
        state.isInitialLoad = false;
      })
      .addCase(validateLink.rejected, (state, action) => {
        state.loading = false;
        state.isValidLink = false;
        state.isInitialLoad = false;
      })
      // validate azure login link
      .addCase(validateAzureLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(validateAzureLink.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.user = action.payload?.data;
      })
      .addCase(validateAzureLink.rejected, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // set password
      .addCase(setPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(setPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(setPassword.rejected, (state, action) => {
        state.loading = false;
      })
      // forgot password
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.loading = false;
      })
      // resend password link
      .addCase(resendPasswordLink.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendPasswordLink.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(resendPasswordLink.rejected, (state, action) => {
        state.loading = false;
      })
      // logout
      .addCase(userLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(userLogout.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(userLogout.rejected, (state, action) => {
        state.loading = false;
      })
      // getuser profile
      .addCase(getUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.user = {
          user: action.payload?.data[0],
          permissions: { ...state?.user?.permissions },
        };
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        state.loading = false;
      })
      // manage asset stage
      .addCase(getAssetStage.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssetStage.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAssetStage = action.payload?.data?.stage;
      })
      .addCase(getAssetStage.rejected, (state) => {
        state.loading = false;
      })
      .addCase(updateAssetStage.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateAssetStage.fulfilled, (state, action) => {
        state.loading = false;
        state.currentAssetStage = action.payload;
      })
      .addCase(updateAssetStage.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { setInitialLoad, clearAuthState, setDefaultPath, setAssetStage } =
  authSlice.actions;
export default authSlice.reducer;
