import React from "react";
import { Box, Container, Grid2, Skeleton } from "@mui/material";

const AuthLayoutSkeleton = () => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      {/* Header with Logo */}

      {/* Main content area */}
      <Box
        sx={{
          flex: 1,
          display: "flex",
          backgroundColor: "var(--whisper-color)",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {/* Background Image Skeleton */}
        <Skeleton
          variant="rectangular"
          sx={{
            position: "absolute",
            top: "0.5%",
            left: "0.5%",
            width: "99%",
            height: "99%",
            bgcolor: "var(--alto-color)",
          }}
        />

        <Container
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            height: "100%",
          }}
        >
          {/* Auth Form Box Skeleton */}
          <Grid2 container>
            <Grid2>
              <Box
                sx={{
                  bgcolor: "var(--whisper-color)",
                  py: 2,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width={300}
                  height={100}
                  sx={{
                    marginLeft: "5px",
                    bgcolor: "var(--alto-color)",
                  }}
                />
              </Box>
            </Grid2>
            <Grid2>
              <Box
                sx={{
                  position: "absolute",
                  right: "10%",
                  top: "50%",
                  transform: "translateY(-50%)",
                  maxWidth: "520px",
                  width: "100%",
                  bgcolor: "white",
                  borderRadius: 1,
                  boxShadow: "0px 4px 4px 0px #00000040",
                  pt: 5,
                  pb: 3,
                  px: 7,
                }}
              >
                {/* Form Content Skeletons */}
                <Box sx={{ width: "100%", mb: 4 }}>
                  <Skeleton
                    variant="rectangular"
                    width="60%"
                    height={32}
                    sx={{ mb: 3, mx: "auto" }}
                  />

                  {/* Form Fields */}
                  <Box sx={{ mb: 3 }}>
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={48}
                      sx={{ mb: 2 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={48}
                      sx={{ mb: 2 }}
                    />
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={48}
                      sx={{ mb: 2 }}
                    />
                  </Box>

                  {/* Button */}
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={48}
                    sx={{ mb: 2 }}
                  />

                  {/* Additional Links */}
                  <Box
                    sx={{ display: "flex", justifyContent: "center", mt: 2 }}
                  >
                    <Skeleton variant="text" width="70%" />
                  </Box>
                </Box>
              </Box>
            </Grid2>
          </Grid2>
        </Container>
        <Box>
          <Grid2
            sx={{
              position: "absolute",
              zIndex: 1,
              bottom: "14%",
              right: "10%",
            }}
          >
            <Skeleton
              variant="text"
              width={400}
              sx={{
                mx: "auto",
              }}
            />
          </Grid2>
        </Box>
      </Box>

      {/* Footer */}
      <Box
        sx={{
          bgcolor: "var(--whisper-color)",
          py: 2,
          textAlign: "center",
        }}
      >
        <Skeleton
          variant="text"
          width={400}
          sx={{
            mx: "auto",
          }}
        />
      </Box>
    </Box>
  );
};

export default AuthLayoutSkeleton;
