import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { FAIL, SUCCESS } from "../constant/constant";

export const createAppAsyncThunk = (
  typePrefix,
  payloadCreator,
  options = {}
) => {
  return createAsyncThunk(
    typePrefix,
    async (payload, thunkAPI) => {
      const { rejectWithValue } = thunkAPI;
      try {
        const response = await payloadCreator(payload, thunkAPI);

        // If status is FAIL, reject the promise
        if (response.data.status === FAIL) {
          if (options?.errorMessage?.show) {
            const errorMessage =
              options?.errorMessage?.message ||
              (typeof response.data.message === "string"
                ? response.data.message
                : "Something Went Wrong");
            enqueueSnackbar(
              typeof errorMessage === "function"
                ? errorMessage(response.data)
                : errorMessage,
              { variant: "error" }
            );
          }
          return rejectWithValue(response.data);
        }

        // If status is SUCCESS, continue with success flow
        if (response.data.status === SUCCESS) {
          // Handle success message if provided

          if (options?.successMessage?.show) {
            const successMessage =
              options.successMessage.message || response.data.message;
            enqueueSnackbar(
              typeof successMessage === "function"
                ? successMessage(response.data)
                : successMessage,
              { variant: "success" }
            );
          }
          return response.data;
        }
      } catch (error) {
        // Handle network or other errors
        const errorMessage =
          error.response?.data?.detail ||
          error.response?.data?.message ||
          options.errorMessage.message ||
          error?.message ||
          "Something went wrong";

        enqueueSnackbar(
          typeof errorMessage === "function"
            ? errorMessage(error)
            : errorMessage,
          { variant: "error" }
        );

        return rejectWithValue(error.message);
      }
    },
    options
  );
};
