import {
  GET_INVENTORY_CHECK_SUMMARY_TYPE,
  GET_INVENTORY_CHECK_TYPE,
  UPDATE_ASSUMED_CONFIG_TYPE,
  UPDATE_INV_CHECK_RP_TYPE,
  UPDATE_REPAIR_PERCENTAGE_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetInventoryCheckService,
  GetAircraftInventoryCheckSummaryService,
  UpdateRepairPercentageService,
  UpdateAssuemedConfigService,
  UpdateRPService,
} from "./inventoryCheckServices";

export const getInventoryCheck = createAppAsyncThunk(
  GET_INVENTORY_CHECK_TYPE,
  GetInventoryCheckService,
  {
    errorMessage: { show: true },
  }
);

export const getAircraftInventoryCheckSummary = createAppAsyncThunk(
  GET_INVENTORY_CHECK_SUMMARY_TYPE,
  GetAircraftInventoryCheckSummaryService,
  {
    errorMessage: { show: true },
  }
);

export const updateRepairPercentange = createAppAsyncThunk(
  UPDATE_REPAIR_PERCENTAGE_TYPE,
  UpdateRepairPercentageService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateConfigSales = createAppAsyncThunk(
  UPDATE_ASSUMED_CONFIG_TYPE,
  UpdateAssuemedConfigService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateInvCheckRP = createAppAsyncThunk(
  UPDATE_INV_CHECK_RP_TYPE,
  UpdateRPService,

  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
