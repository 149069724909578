import {
  ADD_ASSET_TYPE,
  ADD_PROJECTS_TYPE,
  CREAT_ESCALATON_LIST_TYPE,
  CREAT_LLP_VALUATION,
  CREATE_LLP_CYCLE,
  DELETE_ASSET_TYPE,
  DELETE_ESCALATON_TYPE,
  DELETE_LLP_CYCLE,
  DELETE_PROJECTS_TYPE,
  GET_ASSET_TYPE,
  GET_ENGINE_UTILISATION_PARAMS,
  GET_ESCALATON_LIST_TYPE,
  GET_LLP_VALUATION_LIST,
  GET_LLP_VALUATION_PART_DISCRIPTION_OPTION,
  GET_PROJECTS_TYPE,
  PROJECT_SUMMARY_TYPE,
  UPDATE_ASSET_TYPE,
  UPDATE_ENGINE_UTILISATION_PARAMS,
  UPDATE_ESCALATON,
  UPDATE_LLP_VALUATION,
  UPDATE_PROJECTS_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddAssetService,
  AddProjectService,
  createLlpCycleService,
  createLLPValuationListsService,
  creatEscalationService,
  DeleteAssetService,
  deleteEscalationService,
  deleteLlpCycleService,
  DeleteProjectService,
  GetAssetService,
  getEngineUtilisationParamsService,
  getEscalationService,
  getLLPValuaPardiscriptionOptionService,
  getLLPValuationListsService,
  GetProjectService,
  GetProjectSummaryService,
  UpdateAssetService,
  updateEngineUtilisationParamsService,
  updateEscalationvice,
  updateLPValuationListsService,
  UpdateProjectService,
} from "./projectServices";

// projects
export const getProjects = createAppAsyncThunk(
  GET_PROJECTS_TYPE,
  GetProjectService,
  {}
);

export const addProjects = createAppAsyncThunk(
  ADD_PROJECTS_TYPE,
  AddProjectService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateProjects = createAppAsyncThunk(
  UPDATE_PROJECTS_TYPE,
  UpdateProjectService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteProjects = createAppAsyncThunk(
  DELETE_PROJECTS_TYPE,
  DeleteProjectService,
  {
    successMessage: {
      show: true,
      message: (response) => `Project deleted successfully`,
    },
    errorMessage: { show: true },
  }
);

// assets

export const getAssets = createAppAsyncThunk(
  GET_ASSET_TYPE,
  GetAssetService,
  {}
);

export const addAsset = createAppAsyncThunk(ADD_ASSET_TYPE, AddAssetService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateAsset = createAppAsyncThunk(
  UPDATE_ASSET_TYPE,
  UpdateAssetService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteAssets = createAppAsyncThunk(
  DELETE_ASSET_TYPE,
  DeleteAssetService,
  {
    successMessage: {
      show: true,
      message: (response) => `Asset deleted successfully`,
    },
    errorMessage: { show: true },
  }
);

export const getProjectSummary = createAppAsyncThunk(
  PROJECT_SUMMARY_TYPE,
  GetProjectSummaryService,
  {
    errorMessage: { show: true },
  }
);

export const getEngineUtilisationParams = createAppAsyncThunk(
  GET_ENGINE_UTILISATION_PARAMS,
  getEngineUtilisationParamsService
);

export const updatetEngineUtilisationParams = createAppAsyncThunk(
  UPDATE_ENGINE_UTILISATION_PARAMS,
  updateEngineUtilisationParamsService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const creatLLPValues = createAppAsyncThunk(
  CREAT_LLP_VALUATION,
  createLLPValuationListsService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateLLPValues = createAppAsyncThunk(
  UPDATE_LLP_VALUATION,
  updateLPValuationListsService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getLLPValuaPardiscriptionOption = createAppAsyncThunk(
  GET_LLP_VALUATION_PART_DISCRIPTION_OPTION,
  getLLPValuaPardiscriptionOptionService
);

export const getLLPValuationList = createAppAsyncThunk(
  GET_LLP_VALUATION_LIST,
  getLLPValuationListsService
);

export const creatLlpCycle = createAppAsyncThunk(
  CREATE_LLP_CYCLE,
  createLlpCycleService
);

export const deleteLlpCycle = createAppAsyncThunk(
  DELETE_LLP_CYCLE,
  deleteLlpCycleService
);

export const creatEscalation = createAppAsyncThunk(
  CREAT_ESCALATON_LIST_TYPE,
  creatEscalationService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updateEscalation = createAppAsyncThunk(
  UPDATE_ESCALATON,
  updateEscalationvice,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteEscalation = createAppAsyncThunk(
  DELETE_ESCALATON_TYPE,
  deleteEscalationService
);

export const getEscalation = createAppAsyncThunk(
  GET_ESCALATON_LIST_TYPE,
  getEscalationService
);
