import axiosConfig from "../../../config/axiosConfig";
import {
  PRICING_URL,
  UPDATE_BULK_RP_PRICING_URL,
} from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetPricingService = (params) => {
  try {
    const {
      page,
      per_page,
      ordering,
      type,
      slug,
      comp_type,
      part_number,
      serial_number,
      description,
      lookup_pn,
      actual_pn,
      clp,
      module,
      delivery_cr,
      initial_delivery_net_sales,
      eol_cr,
      net_sales,
      sales,
      repair_cost,
      per_cycle,
      current_cr,
      mod,
      ipc_ref,
      typical_pn,
      tier,
      benign_scrap_rate,
      expected_yield,
      sales_total,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      part_number,
      serial_number,
      description,
      lookup_pn,
      actual_pn,
      clp,
      module,
      delivery_cr,
      initial_delivery_net_sales,
      eol_cr,
      net_sales,
      sales,
      repair_cost,
      per_cycle,
      current_cr,
      mod,
      ipc_ref,
      typical_pn,
      tier,
      benign_scrap_rate,
      expected_yield,
      sales_total,
    };

    const queryString = createQueryParams(paramsList);

    const url = comp_type
      ? `${PRICING_URL}/${type}/${slug}/${comp_type}${queryString ? `?${queryString}` : ""}`
      : `${PRICING_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetPricingSummaryService = (params) => {
  try {
    const { type, slug, rp } = params || {};
    const paramsList = {
      repair_percent: rp,
    };
    const queryString = createQueryParams(paramsList);
    const url = `${PRICING_URL}/${type}/${slug}/summary${queryString ? `?${queryString}` : ""}`;
    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const UpdatePricingService = (params) => {
  try {
    const { update, type, slug, rp } = params || {};

    const paramsList = {
      update,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${PRICING_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.patch(url, { repair_percent: rp });
  } catch (error) {
    throw error;
  }
};

export const UpdateRPPricingService = (params) => {
  try {
    const { slug, rp } = params || {};

    const url = `${UPDATE_BULK_RP_PRICING_URL}/${slug}`;

    return axiosConfig.patch(url, { repair_percent: rp });
  } catch (error) {
    throw error;
  }
};
