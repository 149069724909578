import { createSlice } from "@reduxjs/toolkit";
import {
  analyticsFilterDropdowns,
  analyticsGraphsJSON,
  getGraphsList,
} from "./analyticsActions";

export const initialState = {
  filterDropdowns: {},
  loading: false,
  graphsData: {},
  graphLoader: false,
  graphLoaders: {},
  graphsList: {},
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    clearAnalyticsState: (state) => {
      state.loading = false;
      state.filterDropdowns = {};
      state.graphLoader = false;
      state.graphLoaders = {};
      state.graphsData = {};
      state.graphsList = {};
    },
    setGraphLoader: (state, action) => {
      state.graphLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // graph list
      .addCase(getGraphsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGraphsList.fulfilled, (state, action) => {
        state.loading = false;
        state.graphsList = action.payload?.data;
      })
      .addCase(getGraphsList.rejected, (state) => {
        state.loading = false;
      })
      // filters
      .addCase(analyticsFilterDropdowns.pending, (state) => {
        state.loading = true;
      })
      .addCase(analyticsFilterDropdowns.fulfilled, (state, action) => {
        state.loading = false;
        state.filterDropdowns = action.payload?.data;
      })
      .addCase(analyticsFilterDropdowns.rejected, (state, action) => {
        state.loading = false;
      })
      // graphs json
      .addCase(analyticsGraphsJSON.pending, (state, action) => {
        // state.graphLoader = true;
        // state.graphsData = {};
        const uuid = action.meta.arg.uuid;
        state.graphLoaders[uuid] = true;
      })
      .addCase(analyticsGraphsJSON.fulfilled, (state, action) => {
        // state.graphLoader = false;
        // state.graphsData = action.payload?.data;
        const uuid = action.meta.arg.uuid;
        state.graphLoaders[uuid] = false;
        state.graphsData = {
          ...state.graphsData,
          [uuid]: action.payload?.data,
        };
      })
      .addCase(analyticsGraphsJSON.rejected, (state, action) => {
        // state.graphLoader = false;
        // state.graphsData = {};
        const uuid = action.meta.arg.uuid;
        state.graphLoaders[uuid] = false;
        state.graphsData[uuid] = null;
      });
  },
});
export const { clearAnalyticsState, setGraphLoader } = analyticsSlice.actions;
export default analyticsSlice.reducer;
