import {
  FORGOT_PASSSWORD_TYPE,
  GET_ASSET_STAGE_TYPE,
  GET_USER_PROFILE_TYPE,
  LOGIN_TYPE,
  LOGOUT_TYPE,
  REFRESH_TOKEN_TYPE,
  RESENT_PASSWORD_LINK_TYPE,
  SET_PASSSWORD_TYPE,
  UPDATE_ASSET_STAGE_TYPE,
  VALIDATE_AZURE_LINK_TYPE,
  VALIDATE_LINK_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  ForgotPasswordService,
  GetAssetStageService,
  GetUsetrProfileService,
  LoginService,
  LogoutService,
  RefreshTokenSerivce,
  ResendPasswordLinkService,
  SetPasswordService,
  UpdateStageService,
  ValidateAzureLinkService,
  ValidateLinkService,
} from "./authServices";

export const userLogin = createAppAsyncThunk(LOGIN_TYPE, LoginService, {
  successMessage: {
    show: true,
    // message: (response) => `User sfd logged in successfully`,
  },
  errorMessage: {
    show: true,
    // message: (error) => "error",
  },
});

export const validateLink = createAppAsyncThunk(
  VALIDATE_LINK_TYPE,
  ValidateLinkService,
  {
    errorMessage: {
      show: true,
      message: "Link has expired ! please sent again",
    },
  }
);

export const setPassword = createAppAsyncThunk(
  SET_PASSSWORD_TYPE,
  SetPasswordService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const forgotPassword = createAppAsyncThunk(
  FORGOT_PASSSWORD_TYPE,
  ForgotPasswordService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);
export const resendPasswordLink = createAppAsyncThunk(
  RESENT_PASSWORD_LINK_TYPE,
  ResendPasswordLinkService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const refreshToken = createAppAsyncThunk(
  REFRESH_TOKEN_TYPE,
  RefreshTokenSerivce,
  {
    errorMessage: {
      show: true,
      message: "Your Session has expired ! please login again",
    },
  }
);

export const userLogout = createAppAsyncThunk(LOGOUT_TYPE, LogoutService, {
  successMessage: {
    show: true,
  },
  errorMessage: {
    show: true,
  },
});

export const getUserProfile = createAppAsyncThunk(
  GET_USER_PROFILE_TYPE,
  GetUsetrProfileService,
  {}
);

export const validateAzureLink = createAppAsyncThunk(
  VALIDATE_AZURE_LINK_TYPE,
  ValidateAzureLinkService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const getAssetStage = createAppAsyncThunk(
  GET_ASSET_STAGE_TYPE,
  GetAssetStageService
);

export const updateAssetStage = createAppAsyncThunk(
  UPDATE_ASSET_STAGE_TYPE,
  UpdateStageService
);
