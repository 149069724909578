import { createSlice } from "@reduxjs/toolkit";
import {
  getInventoryCheck,
  getAircraftInventoryCheckSummary,
  updateRepairPercentange,
  updateConfigSales,
  updateInvCheckRP,
} from "./inventoryCheckActions";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  inventoryCheckData: {},
  summaryData: {},
  rpFormLoading: false,
  rpFormError: {},
  lruSalesLoader: false,
  updateLoader: false,
};

const inventoryCheckSlice = createSlice({
  name: "inventoryCheck",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    clearInventoryCheckdata: (state) => {
      state.isInitialLoad = true;
      state.loading = false;
      state.inventoryCheckData = {};
      state.summaryData = {};
      state.rpFormError = {};
      state.rpFormLoading = false;
      state.lruSalesLoader = false;
      state.updateLoader = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // inventory check list
      .addCase(getInventoryCheck.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoryCheck.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.inventoryCheckData = action.payload?.data;
      })
      .addCase(getInventoryCheck.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // inventory summary list
      .addCase(getAircraftInventoryCheckSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAircraftInventoryCheckSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryData = action.payload?.data;
      })
      .addCase(getAircraftInventoryCheckSummary.rejected, (state) => {
        state.loading = false;
      })
      // update repair percentage for asset
      .addCase(updateRepairPercentange.pending, (state) => {
        state.rpFormLoading = true;
      })
      .addCase(updateRepairPercentange.fulfilled, (state) => {
        state.rpFormLoading = false;
      })
      .addCase(updateRepairPercentange.rejected, (state, action) => {
        state.rpFormLoading = false;
        state.rpFormError =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update engine lru sales
      .addCase(updateConfigSales.pending, (state) => {
        state.lruSalesLoader = true;
      })
      .addCase(updateConfigSales.fulfilled, (state) => {
        state.lruSalesLoader = false;
      })
      .addCase(updateConfigSales.rejected, (state, action) => {
        state.lruSalesLoader = false;
      })
      // update bulk rp
      .addCase(updateInvCheckRP.pending, (state) => {
        state.updateLoader = true;
        state.isInitialLoad = true;
      })
      .addCase(updateInvCheckRP.fulfilled, (state, action) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
      })
      .addCase(updateInvCheckRP.rejected, (state) => {
        state.updateLoader = false;
        state.isInitialLoad = false;
      });
  },
});

export const { setInitialLoad, clearInventoryCheckdata } =
  inventoryCheckSlice.actions;
export default inventoryCheckSlice.reducer;
