import {
  GET_EBITA_TYPE,
  GET_ENGINE_EBITA_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import { GetEbitaService, GetEngnieEbitaService } from "./ebitaServices";

export const getEbita = createAppAsyncThunk(GET_EBITA_TYPE, GetEbitaService, {
  errorMessage: { show: true },
});

export const engineGetEbita = createAppAsyncThunk(
  GET_ENGINE_EBITA_TYPE,
  GetEngnieEbitaService,
  {
    errorMessage: { show: true },
  }
);
