import axiosConfig from "../../../config/axiosConfig";
import {
  ADD_COLUMN_PREFERENCES_URL,
  DELETE_ATTACHEMENTS_URL,
  GET_CONSTANTS_URL,
  GET_FAMILIES__TYPES_URL,
  GET_FAMILIES__URL,
  GET_FILTER_DROPDOWN_URL,
  GET_GENERAL_COLUMN_PREFERENCES_URL,
  UPDATE_COLUMN_PREFERENCES_URL,
} from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetFilterDropdownService = async (params) => {
  const { module_name } = params;
  return axiosConfig.get(
    `${GET_FILTER_DROPDOWN_URL}?module_name=${module_name}`
  );
};

export const GetGeneralColumnPreferencesService = (params) => {
  return axiosConfig.get(`${GET_GENERAL_COLUMN_PREFERENCES_URL}`);
};

export const AddColumnPreferencesService = (data) => {
  return axiosConfig.post(ADD_COLUMN_PREFERENCES_URL, data);
};

export const GetColumnPreferencesService = (data) => {
  return axiosConfig.get(GET_GENERAL_COLUMN_PREFERENCES_URL, data);
};

export const UpdateColumnPreferencesService = (params) => {
  const { id, filed, module_name } = params;
  return axiosConfig.put(`${UPDATE_COLUMN_PREFERENCES_URL}/${id}/`, {
    module_name: module_name,
    column_preferences: filed,
  });
};

// asset dropdowns

export const GetConstantsService = async (params) => {
  const { constant_types } = params;
  return axiosConfig.get(
    `${GET_CONSTANTS_URL}?constant_types=${constant_types}`
  );
};

// asset families

export const GetAircraftFamilyService = async (params) => {
  const { page, per_page } = params || {};

  const paramsList = {
    page: page || 1,
    per_page: per_page || 1000,
  };
  const queryString = createQueryParams(paramsList);

  return axiosConfig.get(
    `${GET_FAMILIES__URL}/aircraft-families${queryString ? `?${queryString}` : ""}`
  );
};

export const GetEngineFamilyService = async (params) => {
  const { page, per_page } = params || {};

  const paramsList = {
    page: page || 1,
    per_page: per_page || 1000,
  };

  const queryString = createQueryParams(paramsList);
  return axiosConfig.get(
    `${GET_FAMILIES__URL}/engine-families${queryString ? `?${queryString}` : ""}`
  );
};

export const GetEngineFamilyTypesService = async ({ id, per_page }) => {
  const queryString = createQueryParams({ id, per_page });

  const url = `${GET_FAMILIES__TYPES_URL}${queryString ? `?${queryString}` : ""}`;

  return axiosConfig.get(url);
};

export const DeleteAttachmentsService = async ({ slug }) => {
  return axiosConfig.delete(`${DELETE_ATTACHEMENTS_URL}/${slug}`);
};
