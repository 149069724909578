import { createSlice } from "@reduxjs/toolkit";
import { engineGetEbita, getEbita } from "./ebitaAction";

export const initialState = {
  isInitialLoad: true,
  componentInitialLoad: true,
  loading: false,
  ebitaData: {},
  engineEbitaData: {},
};

const ebitaSlice = createSlice({
  name: "ebita",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setComponentInitialLoad: (state, action) => {
      state.componentInitialLoad = action.payload;
    },
    clearEbitaData: (state) => {
      state.initialState = true;
      state.componentInitialLoad = true;
      state.loading = false;
      state.ebitaData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // Aircraft
      .addCase(getEbita.pending, (state) => {
        state.loading = true;
        state.ebitaData = {};
      })
      .addCase(getEbita.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.ebitaData = action.payload?.data;
      })
      .addCase(getEbita.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      //Engine
      .addCase(engineGetEbita.pending, (state) => {
        state.loading = true;
        state.engineEbitaData = {};
      })
      .addCase(engineGetEbita.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.engineEbitaData = action.payload?.data;
      })
      .addCase(engineGetEbita.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      });
  },
});

export const { setInitialLoad, clearEbitaData, setComponentInitialLoad } =
  ebitaSlice.actions;
export default ebitaSlice.reducer;
