import axiosConfig from "../../../config/axiosConfig";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";
import {
  FLIPTRADE_URL,
  CUSTOMER_VENDER_URL,
  DELETE_PART_ROW_URL,
} from "../../../config/config";

export const GetFliptradeService = (params) => {
  try {
    const {
      ordering,
      trade_id,
      created_at,
      customer_id,
      status,
      page,
      per_page,
    } = params || {};

    const paramList = {
      ordering,
      trade_id,
      customer_id: getStringFromArray(customer_id),
      status: getStringFromArray(status),
      created_at,
      page,
      per_page,
    };

    const queryString = createQueryParams(paramList);
    const url = `${FLIPTRADE_URL}${queryString ? `?${queryString}` : ""}`;
    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddFliptradingService = (data) => {
  return axiosConfig.post(`${FLIPTRADE_URL}`, data);
};

export const UpdateFliptradingService = (data) => {
  return axiosConfig.patch(`${FLIPTRADE_URL}`, data);
};

// DropDown for Vendor and Customer
export const GetCustomerService = (data) => {
  return axiosConfig.get(`${CUSTOMER_VENDER_URL}`, data);
};

export const GetVendorService = (data) => {
  try {
    const { is_vendor } = data || {};
    const paramList = { is_vendor };
    const queryString = createQueryParams(paramList);
    const url = `${CUSTOMER_VENDER_URL}${queryString ? `?${queryString}` : ""}`;
    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

// Deleting the Parts row [PATCH]

export const DeletePartsRowService = (data) => {
  const { id } = data;
  return axiosConfig.delete(`${DELETE_PART_ROW_URL}/${id}`);
};
