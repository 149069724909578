import { createSlice } from "@reduxjs/toolkit";
import {
  addAsset,
  addProjects,
  creatEscalation,
  creatLlpCycle,
  creatLLPValues,
  deleteAssets,
  deleteEscalation,
  deleteLlpCycle,
  deleteProjects,
  getAssets,
  getEngineUtilisationParams,
  getEscalation,
  getLLPValuaPardiscriptionOption,
  getLLPValuationList,
  getProjects,
  getProjectSummary,
  updateAsset,
  updateEscalation,
  updateLLPValues,
  updateProjects,
  updatetEngineUtilisationParams,
} from "./projectActions";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  addLoading: false,
  deleteLoading: false,
  formErrors: {},
  projects: {},
  assetIntialLoad: true,
  assets: {},
  addAssetLoading: false,
  assetFormErrors: {},
  projectSummary: {},
  summaryLoad: true,
  engineUtilisation: {},
  llpValuationList: {},
  llpValuationListLoading: true,
  addLlPValuesLoading: true,
  llpFormErrors: {},
  fetchLLPValues: false,
  updateResponseData: {},
  EscalationList: {},
  partDiscriptionOption: {},
  fetchEscalationValues: false,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setAssetInitialLoad: (state, action) => {
      state.assetIntialLoad = action.payload;
    },
    setFetchLLPValues: (state, action) => {
      state.fetchLLPValues = action.payload;
    },
    setProjectSummaryInitialLoad: (state, action) => {
      state.summaryLoad = action.payload;
    },
    clearProjectData: (state) => {
      state.initialState = true;
      state.loading = false;
      state.deleteLoading = false;
      state.addLoading = false;
      state.formErrors = {};
      state.projects = {};
      state.assetIntialLoad = true;
      state.assets = {};
      state.addAssetLoading = false;
      state.assetFormErrors = {};
      state.projectSummary = {};
      state.llpValuationList = {};
    },
    setProjectData: (state, action) => {
      state.projects = { ...state.projects, data: action?.payload };
    },
    setAssetData: (state, action) => {
      state.assets = { ...state.assets, data: action?.payload };
    },
    setLlpValuationList: (state, action) => {
      state.llpValuationList = { ...llpValuationList?.list, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      // project list
      .addCase(getProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjects.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.projects = action.payload?.data;
      })
      .addCase(getProjects.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.projects = {};
      })
      // add new project
      .addCase(addProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addProjects.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update project
      .addCase(updateProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateProjects.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete project
      .addCase(deleteProjects.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteProjects.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteProjects.rejected, (state, action) => {
        state.addLoading = false;
      })
      // assets list
      .addCase(getAssets.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAssets.fulfilled, (state, action) => {
        state.loading = false;
        state.assetIntialLoad = false;
        state.assets = action.payload;
      })
      .addCase(getAssets.rejected, (state) => {
        state.loading = false;
        state.assetIntialLoad = false;
        state.assets = {};
      })
      // add new project
      .addCase(addAsset.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(addAsset.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(addAsset.rejected, (state, action) => {
        state.addAssetLoading = false;
        state.assetFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update new project
      .addCase(updateAsset.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(updateAsset.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(updateAsset.rejected, (state, action) => {
        state.addAssetLoading = false;
        state.assetFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete asset
      .addCase(deleteAssets.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(deleteAssets.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(deleteAssets.rejected, (state, action) => {
        state.addAssetLoading = false;
      })
      // project list
      .addCase(getProjectSummary.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProjectSummary.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryLoad = false;
        state.projectSummary = action.payload?.data;
      })
      .addCase(getProjectSummary.rejected, (state) => {
        state.loading = false;
        state.summaryLoad = false;
        state.projectSummary = {};
      })
      // Get Engine Utilisation
      .addCase(getEngineUtilisationParams.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineUtilisationParams.fulfilled, (state, action) => {
        state.loading = false;
        state.summaryLoad = false;
        state.engineUtilisation = action.payload?.data;
      })
      .addCase(getEngineUtilisationParams.rejected, (state) => {
        state.loading = false;
        state.summaryLoad = false;
        state.engineUtilisation = {};
      })
      // Update Engine Utilisation
      .addCase(updatetEngineUtilisationParams.pending, (state) => {
        state.addAssetLoading = true;
      })
      .addCase(updatetEngineUtilisationParams.fulfilled, (state) => {
        state.addAssetLoading = false;
      })
      .addCase(updatetEngineUtilisationParams.rejected, (state, action) => {
        state.addAssetLoading = false;
        state.assetFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // Create llp Valuation List
      .addCase(creatLLPValues.pending, (state) => {
        state.addLlPValuesLoading = true;
      })
      .addCase(creatLLPValues.fulfilled, (state, action) => {
        state.addLlPValuesLoading = false;
      })
      .addCase(creatLLPValues.rejected, (state, action) => {
        state.addLlPValuesLoading = false;
        state.llpFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // Get llp Valuation List
      .addCase(getLLPValuationList.pending, (state) => {
        state.llpValuationListLoading = true;
      })
      .addCase(getLLPValuationList.fulfilled, (state, action) => {
        state.loading = false;
        state.llpValuationListLoading = false;
        state.llpValuationList = action.payload?.data;
      })
      .addCase(getLLPValuationList.rejected, (state) => {
        state.loading = false;
        state.llpValuationListLoading = false;
        state.llpValuationList = {};
      })
      // Update LLP values
      .addCase(updateLLPValues.pending, (state) => {
        state.addLlPValuesLoading = true;
      })
      .addCase(updateLLPValues.fulfilled, (state, action) => {
        state.addLlPValuesLoading = false;
        state.updateResponseData = action.payload?.data;
      })
      .addCase(updateLLPValues.rejected, (state, action) => {
        state.addLlPValuesLoading = false;
        state.llpFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })

      // Create Escalation List
      .addCase(creatEscalation.pending, (state) => {
        state.addLlPValuesLoading = true;
      })
      .addCase(creatEscalation.fulfilled, (state, action) => {
        state.addLlPValuesLoading = false;
      })
      .addCase(creatEscalation.rejected, (state, action) => {
        state.addLlPValuesLoading = false;
        state.llpFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // Get Escalation List
      .addCase(getEscalation.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEscalation.fulfilled, (state, action) => {
        state.loading = false;
        state.loading = false;
        state.EscalationList = action.payload?.data;
      })
      .addCase(getEscalation.rejected, (state) => {
        state.loading = false;
        state.loading = false;
        state.EscalationList = {};
      })
      // Get part discription option List
      .addCase(getLLPValuaPardiscriptionOption.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLLPValuaPardiscriptionOption.fulfilled, (state, action) => {
        state.loading = false;
        state.loading = false;
        state.partDiscriptionOption = action.payload?.data;
      })
      .addCase(getLLPValuaPardiscriptionOption.rejected, (state) => {
        state.loading = false;
        state.loading = false;
        state.partDiscriptionOption = {};
      })
      // Update Escalation values
      .addCase(updateEscalation.pending, (state) => {
        state.addLlPValuesLoading = true;
      })
      .addCase(updateEscalation.fulfilled, (state, action) => {
        state.addLlPValuesLoading = false;
        state.updateResponseData = action.payload?.data;
      })
      .addCase(updateEscalation.rejected, (state, action) => {
        state.addLlPValuesLoading = false;
        state.llpFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete selected Escalation
      .addCase(deleteEscalation.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteEscalation.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteEscalation.rejected, (state) => {
        state.addLoading = false;
      })
      // Create llp Cycle

      .addCase(creatLlpCycle.pending, (state) => {
        state.addLlPValuesLoading = true;
      })
      .addCase(creatLlpCycle.fulfilled, (state, action) => {
        state.addLlPValuesLoading = false;
      })
      .addCase(creatLlpCycle.rejected, (state, action) => {
        state.addLlPValuesLoading = false;
        state.llpFormErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      .addCase(deleteLlpCycle.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteLlpCycle.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteLlpCycle.rejected, (state) => {
        state.deleteLoading = false;
      });
  },
});

export const {
  setInitialLoad,
  setRoleInitalLoad,
  setAssetInitialLoad,
  clearProjectData,
  setProjectSummaryInitialLoad,
  setProjectData,
  setAssetData,
  engineUtilisation,
  llpValuationList,
  llpValuationListLoading,
  fetchLLPValues,
  setFetchLLPValues,
  updateResponseData,
  setLlpValuationList,
  EscalationList,
  deleteLoading,
  partDiscriptionOption,
} = projectSlice.actions;
export default projectSlice.reducer;
