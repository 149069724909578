import axiosConfig from "../../../config/axiosConfig";
import {
  INVENTORY_CHECK_URL,
  UPDATE_LRU_SALES_URL,
  UPDATE_RP_INVENTORY_CHECK_URL,
} from "../../../config/config";
import { createQueryParams } from "../../../utils/helper";

export const GetInventoryCheckService = (params) => {
  try {
    const {
      page,
      per_page,
      ordering,
      slug,
      type,
      summary,
      comp_type,
      part_number,
      serial_number,
      description,
      quantity,
      ata_chapter_source,
      ata_chapter_aerfin,
      actual_pn,
      clp,
      mod,
      ipc_ref,
      typical_pn,
      tier,
      benign_scrap_rate,
      expected_yield,
      sales_total,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      ordering,
      summary,
      part_number,
      serial_number,
      description,
      quantity,
      ata_chapter_source,
      ata_chapter_aerfin,
      actual_pn,
      clp,
      mod,
      ipc_ref,
      typical_pn,
      tier,
      benign_scrap_rate,
      expected_yield,
      sales_total,
    };
    const queryString = createQueryParams(paramsList);

    const url = comp_type
      ? `${INVENTORY_CHECK_URL}/${type}/${slug}/${comp_type}${queryString ? `?${queryString}` : ""}`
      : `${INVENTORY_CHECK_URL}/${type}/${slug}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const GetAircraftInventoryCheckSummaryService = (params) => {
  try {
    const { slug, type } = params || {};

    const url = `${INVENTORY_CHECK_URL}/${type}/${slug}/summary`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const UpdateRepairPercentageService = ({ data, params }) => {
  const { slug, type, comp_type } = params || {};
  const url = comp_type
    ? `${INVENTORY_CHECK_URL}/${type}/${slug}/${comp_type}`
    : `${INVENTORY_CHECK_URL}/${type}/${slug}`;
  return axiosConfig.patch(url, data);
};

export const UpdateAssuemedConfigService = ({ data, params }) => {
  const { slug, action } = params || {};
  const paramsList = {
    action,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${UPDATE_LRU_SALES_URL}/update/${slug}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.patch(url, data);
};

export const UpdateRPService = (params) => {
  try {
    const { slug, rp } = params || {};

    const url = `${UPDATE_RP_INVENTORY_CHECK_URL}/${slug}`;

    return axiosConfig.patch(url, { repair_percent: rp });
  } catch (error) {
    throw error;
  }
};
