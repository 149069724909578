import {
  GET_PINNED_GRAPH_TYPE,
  PIN_GRAPH_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import { GetPinnedGraphsService, PinGraphService } from "./homeServices";

export const getPinnedGraphs = createAppAsyncThunk(
  GET_PINNED_GRAPH_TYPE,
  GetPinnedGraphsService
);

export const pinGraph = createAppAsyncThunk(PIN_GRAPH_TYPE, PinGraphService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});
