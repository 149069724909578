import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  ADD_FLIPTRADING_TYPE,
  GET_FLIPTRADE_TYPE,
  UPDATE_FLIPTRADING_TYPE,
  CUSTOMER_TYPE,
  VENDER_TYPE,
  DELET_PART_ROW_TYPE,
} from "../../../constant/asynActionsType";

import {
  AddFliptradingService,
  UpdateFliptradingService,
  GetFliptradeService,
  GetCustomerService,
  GetVendorService,
  DeletePartsRowService,
} from "./fliptradeService";

//GET
export const getFliptrade = createAppAsyncThunk(
  GET_FLIPTRADE_TYPE,
  GetFliptradeService,
  {}
);

export const addFliptrading = createAppAsyncThunk(
  ADD_FLIPTRADING_TYPE,
  AddFliptradingService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// Update
export const updateFliptrading = createAppAsyncThunk(
  UPDATE_FLIPTRADING_TYPE,
  UpdateFliptradingService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// GET Customer
export const getCustomer = createAppAsyncThunk(
  CUSTOMER_TYPE,
  GetCustomerService,
  {}
);

// DELETE PART ROW

export const deletePartRow = createAppAsyncThunk(
  DELET_PART_ROW_TYPE,
  DeletePartsRowService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: { show: true },
  }
);

// GET Vendor
export const getVendor = createAppAsyncThunk(VENDER_TYPE, GetVendorService, {});