import { createSlice } from "@reduxjs/toolkit";
import {
  getForecast,
  getInventoryMakeup,
  getPreviousSales,
  getSalesFmv,
  getTopSellApp,
} from "./demandForecastActions";

export const initialState = {
  pSalesLoader: false,
  pSalesData: {},
  topSellLoader: false,
  topSellData: {},
  foreCastLoader: false,
  foreCastData: {},
  salesFmvLoader: false,
  salesFmvData: {},
  invMakeupLoader: false,
  invMakeupData: {},
};

const dfSlice = createSlice({
  name: "df",
  initialState,
  reducers: {
    clearDFState: (state) => {
      state.pSalesLoader = false;
      state.pSalesData = {};
      state.topSellLoader = false;
      state.topSellData = {};
      state.foreCastLoader = false;
      state.foreCastData = {};
      state.salesFmvLoader = false;
      state.salesFmvData = {};
      state.invMakeupLoader = false;
      state.invMakeupData = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // previous sales
      .addCase(getPreviousSales.pending, (state) => {
        state.pSalesLoader = true;
      })
      .addCase(getPreviousSales.fulfilled, (state, action) => {
        state.pSalesLoader = false;
        state.pSalesData = action.payload?.data;
      })
      .addCase(getPreviousSales.rejected, (state) => {
        state.pSalesLoader = false;
      })
      // top sell app
      .addCase(getTopSellApp.pending, (state) => {
        state.topSellLoader = true;
      })
      .addCase(getTopSellApp.fulfilled, (state, action) => {
        state.topSellLoader = false;
        state.topSellData = action.payload?.data;
      })
      .addCase(getTopSellApp.rejected, (state) => {
        state.topSellLoader = false;
      })
      //forecast
      .addCase(getForecast.pending, (state) => {
        state.foreCastLoader = true;
      })
      .addCase(getForecast.fulfilled, (state, action) => {
        state.foreCastLoader = false;
        state.foreCastData = action.payload?.data;
      })
      .addCase(getForecast.rejected, (state) => {
        state.foreCastLoader = false;
      })
      // sales vs fmv
      .addCase(getSalesFmv.pending, (state) => {
        state.salesFmvLoader = true;
      })
      .addCase(getSalesFmv.fulfilled, (state, action) => {
        state.salesFmvLoader = false;
        state.salesFmvData = action.payload?.data;
      })
      .addCase(getSalesFmv.rejected, (state) => {
        state.salesFmvLoader = false;
      })
      // inventory make up
      .addCase(getInventoryMakeup.pending, (state) => {
        state.invMakeupLoader = true;
      })
      .addCase(getInventoryMakeup.fulfilled, (state, action) => {
        state.invMakeupLoader = false;
        state.invMakeupData = action.payload?.data;
      })
      .addCase(getInventoryMakeup.rejected, (state) => {
        state.invMakeupLoader = false;
      });
  },
});
export const { clearDFState } = dfSlice.actions;
export default dfSlice.reducer;
