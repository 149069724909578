import {
  GET_PRICING_SUMMARY_TYPE,
  GET_PRICING_TYPE,
  UPDATE_PRICING_RP_TYPE,
  UPDATE_PRICING_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetPricingService,
  GetPricingSummaryService,
  UpdatePricingService,
  UpdateRPPricingService,
} from "./pricingServices";

export const getPricing = createAppAsyncThunk(
  GET_PRICING_TYPE,
  GetPricingService,
  {
    errorMessage: { show: true },
  }
);

export const getPricingSummary = createAppAsyncThunk(
  GET_PRICING_SUMMARY_TYPE,
  GetPricingSummaryService,
  {
    errorMessage: { show: true },
  }
);

export const updatePricing = createAppAsyncThunk(
  UPDATE_PRICING_TYPE,
  UpdatePricingService,

  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const updatePricingRP = createAppAsyncThunk(
  UPDATE_PRICING_RP_TYPE,
  UpdateRPPricingService,

  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);
