import moment from "moment";
import axiosConfig from "../../../config/axiosConfig";
import {
  ASSET_URL,
  CREATE_ESCALATON_LIST_URL,
  CREATE_LLP_CYCLE_URL,
  CREATE_LLP_VALUATION_LIST_URL,
  DELETE_ESCALATON_LIST_URL,
  GET_ESCALATON_LIST_URL,
  GET_LLP_VALUATION_LIST_URL,
  GET_LLP_VALUATION_PART_DICRIPTION_OPTION_URL,
  PROJECT_URL,
  PROJECT_URL_ENGINE_UTILISATION,
  UPDATE_ESCALATON_LIST_URL,
  UPDATE_LLP_VALUATION_LIST_URL,
} from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const GetProjectService = (params) => {
  try {
    const {
      page,
      per_page,
      ordering,
      condition,
      project_id,
      seller,
      last_operator,
      region,
      status,
      estimated_price,
      first_round_bid_date,
      operating_region,
    } = params || {};
    const paramsList = {
      page,
      per_page,
      ordering,
      condition,
      project_id,
      seller,
      last_operator,
      region,
      status: getStringFromArray(status),
      estimated_price,
      operating_region,
      first_round_bid_date: first_round_bid_date
        ? moment(first_round_bid_date).format("YYYY-MM-DD")
        : "",
    };

    const queryString = createQueryParams(paramsList);

    const url = `${PROJECT_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddProjectService = ({ data, headers }) => {
  return axiosConfig.post(`${PROJECT_URL}/`, data, { headers });
};

export const UpdateProjectService = ({ data, id, headers }) => {
  return axiosConfig.patch(`${PROJECT_URL}/${id}`, data, { headers });
};

export const DeleteProjectService = ({ id }) => {
  return axiosConfig.delete(`${PROJECT_URL}/${id}`);
};

// assets

export const GetAssetService = (params) => {
  try {
    const {
      page,
      per_page,
      project,
      ordering,
      aircraft_dom,
      condition,
      last_operator,
      location_of_asset,
      msn_esn,
      operating_region,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      project,
      ordering,
      aircraft_dom,
      condition: getStringFromArray(condition),
      last_operator,
      location_of_asset,
      msn_esn,
      operating_region: getStringFromArray(operating_region),
    };

    const queryString = createQueryParams(paramsList);
    const url = `${ASSET_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddAssetService = ({ data, headers }) => {
  return axiosConfig.post(`${ASSET_URL}`, data, { headers });
};

export const UpdateAssetService = ({ data, headers }) => {
  return axiosConfig.patch(`${ASSET_URL}`, data, { headers });
};

export const DeleteAssetService = ({ id }) => {
  return axiosConfig.delete(`${ASSET_URL}/${id}`);
};

export const GetProjectSummaryService = (params) => {
  try {
    return axiosConfig.get(`${PROJECT_URL}/project/summary`);
  } catch (error) {
    throw error;
  }
};

export const getEngineUtilisationParamsService = (id) => {
  try {
    return axiosConfig.get(`${PROJECT_URL_ENGINE_UTILISATION}/${id}`);
  } catch (error) {
    throw error;
  }
};

export const updateEngineUtilisationParamsService = ({ id, data }) => {
  try {
    return axiosConfig.patch(`${PROJECT_URL_ENGINE_UTILISATION}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const createLLPValuationListsService = ({ id, data }) => {
  try {
    return axiosConfig.post(`${CREATE_LLP_VALUATION_LIST_URL}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const creatEscalationService = ({ id, data }) => {
  try {
    return axiosConfig.post(`${CREATE_ESCALATON_LIST_URL}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const updateLPValuationListsService = ({ id, data }) => {
  try {
    return axiosConfig.patch(`${UPDATE_LLP_VALUATION_LIST_URL}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const updateEscalationvice = ({ id, data }) => {
  try {
    return axiosConfig.patch(`${UPDATE_ESCALATON_LIST_URL}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const getLLPValuationListsService = (id) => {
  try {
    return axiosConfig.get(`${GET_LLP_VALUATION_LIST_URL}/${id}`);
  } catch (error) {
    throw error;
  }
};
export const getLLPValuaPardiscriptionOptionService = (id) => {
  try {
    return axiosConfig.get(
      `${GET_LLP_VALUATION_PART_DICRIPTION_OPTION_URL}/${id}`
    );
  } catch (error) {
    throw error;
  }
};

export const createLlpCycleService = ({ id, data }) => {
  try {
    return axiosConfig.post(`${CREATE_LLP_CYCLE_URL}/${id}`, data);
  } catch (error) {
    throw error;
  }
};

export const deleteLlpCycleService = ({ id, data }) => {
  try {
    const queryString = createQueryParams(data);
    return axiosConfig.delete(`${CREATE_LLP_CYCLE_URL}/${id}?${queryString}`);
  } catch (error) {
    throw error;
  }
};

export const deleteEscalationService = (data) => {
  return axiosConfig.delete(`${DELETE_ESCALATON_LIST_URL}/${data?.id}`);
};

export const getEscalationService = (id) => {
  try {
    return axiosConfig.get(`${GET_ESCALATON_LIST_URL}/${id}`);
  } catch (error) {
    throw error;
  }
};
