import {
  FORECAST_DF_TYPE,
  GET_INV_MAKEUP_TYPE,
  GET_SALES_FMV_TYPE,
  PREVOUS_SALES_DF_TYPE,
  TOP_SELL_APP_DF_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetForecastService,
  GetInventoryMakeupService,
  GetPreviousSalseService,
  GetSalesFmvService,
  GetTopSellAppService,
} from "./demandForecastServices";

export const getPreviousSales = createAppAsyncThunk(
  PREVOUS_SALES_DF_TYPE,
  GetPreviousSalseService
);

export const getTopSellApp = createAppAsyncThunk(
  TOP_SELL_APP_DF_TYPE,
  GetTopSellAppService
);

export const getForecast = createAppAsyncThunk(
  FORECAST_DF_TYPE,
  GetForecastService
);

export const getSalesFmv = createAppAsyncThunk(
  GET_SALES_FMV_TYPE,
  GetSalesFmvService
);

export const getInventoryMakeup = createAppAsyncThunk(
  GET_INV_MAKEUP_TYPE,
  GetInventoryMakeupService
);
