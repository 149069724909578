import { createSlice } from "@reduxjs/toolkit";
import {
  getEngineCashFlowResult,
  getEngineIndividualCashflowParam,
} from "./engineCashFlowAction";

export const initialState = {
  isInitialLoad: true,
  loading: false,
  addLoading: false,
  engineCashflowResult: {},
  assetIntialLoad: true,
  assets: {},
  addAssetLoading: false,
  engineEndividualCashflowParam: {},
};

const engineCashflow = createSlice({
  name: "cashflow",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setAssetInitialLoad: (state, action) => {
      state.assetIntialLoad = action.payload;
    },
    clearProjectData: (state) => {
      state.initialState = true;
      state.loading = false;
      state.addLoading = false;
      state.engineCashflowResult = {};
      state.assetIntialLoad = true;
      state.assets = {};
      state.addAssetLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // Engine cash flow Result
      .addCase(getEngineCashFlowResult.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineCashFlowResult.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.engineCashflowResult = action.payload?.data;
      })
      .addCase(getEngineCashFlowResult.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.cashflowResult = {};
      })

      // get cash flow INDIVIDUAL CASHFLOW PARAM
      .addCase(getEngineIndividualCashflowParam.pending, (state) => {
        state.loading = true;
      })
      .addCase(getEngineIndividualCashflowParam.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.engineEndividualCashflowParam = action.payload?.data;
      })
      .addCase(getEngineIndividualCashflowParam.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.engineEndividualCashflowParam = {};
      });

    // // update cash Flow
    // .addCase(updateCashFlow.pending, (state) => {
    //   state.addLoading = true;
    // })
    // .addCase(updateCashFlow.fulfilled, (state, action) => {
    //   state.addLoading = false;
    //   state.addCashFlow = action?.payload.data;
    // })
    // .addCase(updateCashFlow.rejected, (state, action) => {
    //   state.addLoading = false;
    //   state.formErrors =
    //     typeof action.payload?.message === String
    //       ? {}
    //       : action.payload?.message;
    // });
    // delete project
    // .addCase(deleteProjects.pending, (state) => {
    //   state.addLoading = true;
    // })
    // .addCase(deleteProjects.fulfilled, (state) => {
    //   state.addLoading = false;
    // })
    // .addCase(deleteProjects.rejected, (state, action) => {
    //   state.addLoading = false;
    // })
    // // assets list
    // .addCase(getAssets.pending, (state) => {
    //   state.loading = true;
    // })
    // .addCase(getAssets.fulfilled, (state, action) => {
    //   state.loading = false;
    //   state.assetIntialLoad = false;
    //   state.assets = action.payload;
    // })
    // .addCase(getAssets.rejected, (state) => {
    //   state.loading = false;
    //   state.assetIntialLoad = false;
    //   state.assets = {};
    // })
    // add new project
    // .addCase(addAsset.pending, (state) => {
    //   state.addAssetLoading = true;
    // })
    // .addCase(addAsset.fulfilled, (state) => {
    //   state.addAssetLoading = false;
    // })
    // .addCase(addAsset.rejected, (state, action) => {
    //   state.addAssetLoading = false;
    //   state.assetFormErrors =
    //     typeof action.payload?.message === String
    //       ? {}
    //       : action.payload?.message;
    // })
    // update new project
    // .addCase(updateAsset.pending, (state) => {
    //   state.addAssetLoading = true;
    // })
    // .addCase(updateAsset.fulfilled, (state) => {
    //   state.addAssetLoading = false;
    // })
    // .addCase(updateAsset.rejected, (state, action) => {
    //   state.addAssetLoading = false;
    //   state.assetFormErrors =
    //     typeof action.payload?.message === String
    //       ? {}
    //       : action.payload?.message;
    // })
    // delete asset
    // delete asset
    // .addCase(deleteAssets.pending, (state) => {
    //   state.addAssetLoading = true;
    // })
    // .addCase(deleteAssets.fulfilled, (state) => {
    //   state.addAssetLoading = false;
    // })
    // .addCase(deleteAssets.rejected, (state, action) => {
    //   state.addAssetLoading = false;
    // });
  },
});

export const {
  setInitialLoad,
  setRoleInitalLoad,
  setAssetInitialLoad,
  clearProjectData,
} = engineCashflow.actions;
export default engineCashflow.reducer;
